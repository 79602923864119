import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCVO11tEXi4scnpzQynRMOFCm0nEUVZiw4",
  authDomain: "vue-dojo-blog-b0784.firebaseapp.com",
  projectId: "vue-dojo-blog-b0784",
  storageBucket: "vue-dojo-blog-b0784.appspot.com",
  messagingSenderId: "535081567926",
  appId: "1:535081567926:web:add169690ba33747090817",
};

firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore, timestamp };
